<script setup>
import { headerGrid, mobileHeaderGrid } from "../assets/data/header.js";
import { paragraphs } from "../assets/data/hp.js";

const ctas = [
  {
    link: "https://www.aiawards.cz/",
    type: "AI AWARDS",
    external: true,
  },
  {
    link: "#program",
    type: "PROGRAM",
    bold: true,
  },
];

const metaOptions = {
  title: `Dny AI | 2024`,
  description:
    "Dva týdny, které napříč Českem představí umělou inteligenci v našem světě",
  image: "https://www.dny.ai/images/og_dny-ai.jpg",
};
useMeta(metaOptions);

const stripsy =
  "dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023 dny ai 2023";
</script>

<template>
  <div>
    <Menu />
    <ArrowScroll />
    <div class="banner">
      <Grid
        :itemsPerRow="8"
        :padding="true"
        :sequence="headerGrid"
        :mobileSequence="mobileHeaderGrid"
      />
      <CTAs :ctas="ctas" />
    </div>
    <div class="bg-gradient">
      <CityStrip />
      <CityStrip :reverse="true" />
    </div>

    <About />
    <Curator />

    <Calendar />
    <div>
      <div class="strips sticky">
        <Strip :text="stripsy" :id="1" />
        <Strip :text="stripsy" :stroked="true" :id="2" />
        <div class="text text-container" v-html="paragraphs[2].content"></div>
      </div>
      <Video />
      <div class="space"></div>
      <!-- <Quotes /> -->
    </div>

    <Organisers />
    <Footer />
  </div>
</template>

<style scss>
.banner {
  min-height: 100vh;
  background-image: url("/images/igor-omilaev-unsplash.jpg");
  background-size: cover;
  background-position: center center;
}

section {
  padding-bottom: 25vh;
  padding-top: 25vh;
  position: relative;
  @media (max-width: 991px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
}
.image-container {
  @media (max-width: 767px) {
    line-height: 0;
  }
}
h1 {
  color: black;
}
.half {
  img {
    width: 100%;
  }
}

.text-container {
  padding: 24px 48px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 24px;
  }
  @media (min-width: 1501px) {
    padding: 6rem;
  }
}

.text {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  text-align: center;
  padding: 12px;
  @media (max-width: 1501px) {
    max-width: 1000px;
  }
}

.strips {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    height: auto;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.space {
  height: 400px;
  @media (max-width: 991px) {
    height: 0;
  }
}
#o-akci {
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
</style>
