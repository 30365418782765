<script setup>
const props = defineProps(["reverse"]);
import cities from "../assets/gql/cities.gql";

const { data } = await useAsyncQuery(cities);

const orderedCities = ["Praha", "Brno", "Plzeň", "Ostrava"];

const definedCities = ref([]);
data.value.cities.forEach((city) => {
  if (!definedCities.value.includes(city.name)) {
    definedCities.value.push(city.name);
  }
});

const nonMatchingCities = definedCities.value.filter(
  (city) => !orderedCities.includes(city)
);

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const shuffledNonMatchingCities = shuffleArray(nonMatchingCities);
const allCities = orderedCities.concat(shuffledNonMatchingCities);

// Check reverse prop to determine animation direction
const animationDirection = computed(() =>
  props.reverse ? "reverse-spin" : "spin"
);
</script>

<template>
  <div :class="['strip', { first: reverse }]">
    <h3 class="cutout-text" :class="animationDirection">
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
    </h3>
    <h3 class="cutout-text" :class="animationDirection">
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
      <span v-for="city in allCities">{{ city }}&nbsp;</span>
    </h3>
  </div>
</template>

<style scoped>
.strip {
  background-color: black;
  width: 100%;
  mix-blend-mode: multiply;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.cutout-text {
  color: white;
  font-weight: 300;
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
}

.first {
  margin-top: -18px;
}

.spin {
  animation: spin 120s linear infinite;
}

.reverse-spin {
  animation: reverse-spin 120s linear infinite;
}

@keyframes spin {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes reverse-spin {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
